import React, { Component } from "react";
import { Typewriter } from "react-simple-typewriter";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      titles: [],
      titles1: [],
      isMobile: window.innerWidth < 768, // Detect if user is on mobile
    };
    this.onThemeSwitchChange = this.onThemeSwitchChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth < 768 });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sharedData !== this.props.sharedData) {
      if (this.props.sharedData.titles) {
        this.setState({
          titles: [...this.props.sharedData.titles],
          titles1: [...this.props.sharedData.titles1],
        });
      }
    }
  }

  onThemeSwitchChange(checked) {
    this.setState({ checked });
    this.setTheme();
  }

  setTheme() {
    const body = document.body;
    const newTheme = body.getAttribute("data-theme") === "dark" ? "light" : "dark";
    body.setAttribute("data-theme", newTheme);
  }

  render() {
    if (!this.props.sharedData) {
      return <p>Loading...</p>;
    }

    const { isMobile, titles, titles1 } = this.state;
    const name = this.props.sharedData.name;

    return (
     <header id="home" className={`header-container ${isMobile ? "mobile-header" : ""}`}>
        <div className="container text-center align-items-center">
          <div className="row align-items-center">
            {/* Titles Section */}
            <div className={`col-md-4 ${isMobile ? "text-center" : "text-left"}`}  style={{ margin: 0, padding: 0 }}>
              <h3>
                {titles.map((title, index) => (
                  <div key={index} className="mb-2">{title}</div>
                ))}
              </h3>
            </div>

            {/* Icon & Typewriter */}
            <div className="col-md-4 text-center"  style={{ margin: 0, padding: 0 }}>
              <span className="iconify header-icon" data-icon="la:laptop-code" data-inline="false"></span>
              <h2 className="mb-0">
                <Typewriter words={[name]} loop={false} cursor cursorStyle="|" typeSpeed={100} deleteSpeed={50} />
              </h2>
            </div>

            {/* Images */}
            <div className={`col-md-4 ${isMobile ? "text-center" : "text-right"}`}>
              {titles1.map((imgSrc, index) => (
                <img
                  key={index}
                  src={imgSrc}
                  alt={`img-${index}`}
                  height="60"
                  className="m-2"
                  style={{ display: "inline-block" }}
                />
              ))}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
